@import '../../Assets//Styles/variables.scss';

.login_container{
    height: 60vh;
    width:400px;
    padding: 20px;
}

.login_container h2{
    color:$primary-text-color;
    margin-bottom: 10px;
}

.login_container p{
    color:$sub-text-color;
    margin-bottom: 20px;
}