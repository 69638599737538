.relative-class {
  position: relative;
  input {
    background-color: #f0f0f0;
    color: #616161;
    width: 100%;
    padding: 9px;
    border-radius: 5px;
    border: none;
    font-weight: 700;
    outline: none;
    height: 49px;
  }
  .date-range-picker-wrapper {
    position: absolute;
    top: 100%;
    left: 10px;
    z-index: 10;
  }
}
