@import "../../../Assets//Styles/variables.scss";

.page_wrapper {
  max-width: 100vw;
  min-height: 100vh;
}
.page_wrapper .sidebar_container {
  max-width: 100%;
  min-width: 260px;
  height: 100vh;
  padding: 10px;
}
.page_wrapper .sidebar_container .fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 250px;
}
.page_wrapper .sidebar {
  border-radius: 15px;
  background-color: $primary-color;
  height: calc(100vh - 20px);
  margin-top: 10px;
  color: #ffffffde;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 16px;
}

.page_wrapper .sidebar_container_flex {
  max-width: 100%;
  min-height: 100vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.page_wrapper .sidebar_container_flex .navbar {
  height: 60px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.page_wrapper .sidebar_container_flex .navbar .Mui-selected {
  background-color: $primary-dark-color !important;
  color: $white-text;
  border-radius: 5px !important;
  opacity: 1 !important;
}
.page_wrapper .divider {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  border-bottom-width: thin;
  background-color: transparent;
  /* background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0))!important; */
  height: 0.0725rem;
  margin: 1rem 0;
  border-bottom: none;
  opacity: 0.25;
  background-color: transparent;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #ffffff,
    rgba(255, 255, 255, 0)
  ) !important;
}

.dashboard-search {
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding-left: 20px;
  background-color: $background-dark-color;
}
.dashboard-search input {
  border: none;
  outline: none;
  font-size: 18px;
  height: 100%;
  background-color: $background-dark-color;
  color: #a3aed0;
  min-width: 300px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoWrapper img {
  max-width: 50px;
  height: auto;
}
.logoWrapper h4 {
  color: #ffffff;
  font-size: larger;
  margin-top: 10px;
}
.logoWrapper span {
  margin-left: 10px;
  color: #a3aed0;
  font-size: medium;
}
