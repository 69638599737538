$background-color: #f4f8f9;
$background-dark-color: #f4f8f9;

$primary-color: #d64045;
$primary-dark-color: #ba363b;
$secondary-color: #f18805;

$primary-text-color: #25262a;
$sub-text-color: #616060;
$white-text: #ffffff;
$black-text: #000000;

$primary-button-color: #2364aa;
$secondary-button-color: #8ea604;
